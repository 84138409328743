.basket{padding:80px 0;background-image:url(../../images/bg.png);background-size:unset}
.basket .left .basket_product{background:#fff;display:flex;justify-content:space-between;align-items:center;border-radius:8px;padding:15px 8px;margin-bottom:16px}
.basket .left{padding:0 7px;display:flex;flex-direction:column;justify-content:flex-start;margin-bottom:20px}
.basket .left .basket_product div{display:flex;justify-content:flex-start;align-items:center}
.basket .left .basket_product div span{font-size:18px;color:#666;margin-left:10px}
.basket .basket_title{font-weight:600;font-size:48px;color:#f90;margin-bottom:45px}
.basket .right{padding:0 7px;justify-content:flex-start}
.basket .right button{width:100%;justify-content:center;padding:10px 12px}
.basket .right .right_basket_bg{background:#fff;border-radius:8px;width:100%;padding:30px 20px;display:flex;flex-direction:column;margin-bottom:20px}
.basket .right .right_basket_bg div{display:flex;justify-content:space-between;align-items:center;margin-bottom:6px}
.basket .right .right_basket_bg .count{font-weight:600;font-size:20px;margin-bottom:24px;color:#333}
.basket .right .right_basket_bg div span{font-size:14px;line-height:17px;margin-bottom:0;color:#333}
.basket .right .right_basket_bg div p{margin:0;font-size:14px;color:#333}
.basket .right .count{font-weight:600;font-size:20px;color:#333}
.basket .left .basket_counter{display:flex;justify-content:flex-end;align-items:center;margin-right:20px}
.basket .left .basket_counter div{display:flex;justify-content:flex-end;align-items:center}
.basket .left .basket_counter div p{margin:0}
.basket .left .basket_counter div img{border:1px solid #e0e0e0;border-radius:4px;padding:3px;margin-left:35px;cursor:pointer}
.basket .left .basket_counter .basket_calc{background:#e0e0e0;border-radius:2px;display:flex;justify-content:space-around;align-items:center;padding:2px;margin-left:20px}
.basket .left .basket_counter div p{font-weight:600;font-size:20px;color:#333}
.basket .left .basket_counter .basket_calc span{padding:0 10px;border-radius:2px;margin:0;background:#fff;cursor:pointer}
.basket .left .basket_counter .basket_calc p{margin:0;margin:0 50px;font-weight:500;font-size:14px;line-height:17px;color:#222}
.basket_product .basket_column div img{border-radius:4px;height:60px}
.payment_tables{width:100%;border-collapse:separate;border-spacing:0;margin-bottom:15px;cursor:pointer}
.payment_tables th{text-align:center;border:1px solid #7e5a24;color:#fff;width:50%;font-size:13px;font-weight:200}
.payment_tables th span{color:#c5c5c5;font-size:14px;font-weight:500}
.basket .payment_card{display:flex;justify-content:flex-end;align-items:center;flex-wrap:wrap;padding:0;margin-top:30px;border:.3px solid gray;padding:20px 10px;border-radius:6px}
.basket .payment_card span{margin-bottom:5px}
.basket .payment_card .payment_text{width:100%;display:flex;justify-content:center}
.basket .payment_card .payment_text span{font-weight:600;font-size:25px;color:#f90;text-align:center;margin-bottom:20px}
.basket .payment_card .tolov{width:150px;height:80px;display:flex;justify-content:center;align-items:center;background:#fff;padding:5px 12px;border-radius:5px;margin-left:20px}
.basket .payment_card .tolov button{border:none;background:0 0}
.basket .payment_card .tolov:hover{box-shadow:0 0 20px 5px #535351;cursor:pointer}
.basket .payment_card .tolov .click_payment button{background:0 0;border:none}
.basket .payment_card div img{max-width:100%}
.basket_clear{display:flex;justify-content:center;flex-direction:column;align-items:center;width:100%}
.basket_clear h2{color:#fff;font-size:30px;font-weight:500}
.basket_clear span{color:#adadad;margin-bottom:15px;font-weight:400;font-size:20px}
.basket_clear a{color:#fff}
.basket_clear a:hover{color:#fff}
.close{border-radius:4px;border:none;color:#fff;font-size:14px;margin-top:20px}
.close:focus{box-shadow:none!important}
@media (max-width:1199px){
.basket .left .basket_product div span{font-size:16px;margin-left:10px}
.basket .left .basket_counter .basket_calc{margin-left:15px}
.basket .right .right_basket_bg{padding:30px 10px}
}
@media (max-width:991px){
.basket{padding:20px 0}
.basket .left{margin-bottom:20px}
.basket_clear span{font-size:18px}
.basket .row{justify-content:flex-end}
}
@media (max-width:767px){
.basket .left .basket_counter{margin-right:5px}
.basket .left .basket_counter div img{margin-left:10px}
.basket .left .basket_product div span{margin-left:0}
.basket_product .basket_column{display:flex;flex-direction:column;min-width:140px;align-items:center}
.basket .left .basket_counter .basket_calc{margin-left:15px}
.basket .left .basket_counter .basket_calc p{margin:0 25px}
.basket .left .basket_counter div p{font-size:18px}
.basket .right .right_basket_bg .count{font-size:25px}
.basket .right .right_basket_bg div span{font-size:18px;line-height:17px;font-weight:600}
.basket .right .right_basket_bg div p{font-size:16px}
.bask_check h4{margin-top:30px;font-size:34px}
.basket .left .basket_counter .basket_calc{margin-left:10px}
.basket_clear h2{font-size:26px}
.basket_clear span{font-size:14px}
.left .basket_clear img{width:150px}
}
@media (max-width:575px){
.basket_product .basket_column{display:flex;flex-direction:column}
.basket .left .basket_counter{flex-direction:column;justify-content:space-between;margin-right:0}
.basket .left .basket_counter .basket_calc{margin-left:0}
.basket .left .basket_counter .basket_calc p{margin:0 40px}
.basket .left .basket_counter div p{margin-right:50px;margin-top:0}
.basket .left .basket_counter div{margin-top:0}
.basket .basket_title{font-size:40px}
.basket .left .basket_product div{margin-bottom:8px;width:100%;display:flex;justify-content:center}
.basket .left .basket_product div span{text-align:center}
.basket .left .basket_product{padding:5px 5px}
.basket .left .basket_product div span{font-size:13px;margin-left:10px}
.basket .right .right_basket_bg div p{font-size:18px;font-weight:600}
.basket .right .right_basket_bg div span{font-weight:500}
.basket_product .basket_column div img{width:auto}
.basket_clear h2{font-size:19px}
.basket .basket_title{margin-bottom:20px}
.basket .left .basket_counter div p{font-size:15px}
}