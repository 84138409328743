.categor_pc .rights{display:flex;flex-wrap:wrap;padding:0 5px!important}
.categor_pc .left{padding:0 5px!important}
.categor_pc{padding:70px 0}
.categor_pc .rights .cards{background:#fff;padding:15px 10px 15px;border-radius:16px;display:flex;flex-direction:column;justify-content:space-between;width:31.5%;margin:8px 6px}
.categor_pc .rights .cards .cards_bottom{display:flex;flex-direction:column}
.categor_pc .rights .cards .cards_bottom div{display:flex;justify-content:space-between;align-items:center}
.categor_pc .rights .cards .cards_bottom div img{cursor:pointer}
.categor_pc .rights .cards div span{font-size:18px;line-height:22px;color:#666;margin-bottom:8px;font-weight:600;overflow:hidden;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical}
.categor_pc .rights .cards div p{font-weight:600;font-size:20px;line-height:24px;color:#333;margin-bottom:0}
.categor_pc .rights .cards .img_category img{max-width:100%;border-radius:4px;max-height:270px}
.categor_pc .rights .cards .img_category{display:flex;justify-content:center;margin-bottom:10px}
.categor_pc .rights .cards button{width:100%;justify-content:center;margin-top:30px}
.form-select{background:0 0!important;font-family:Montserrat;font-weight:600;font-size:16px;line-height:20px;color:#fff!important;border-radius:0!important;border-left:none!important;border-right:none!important;border-top:none!important;border-bottom:1px solid #4f4f4f!important;box-shadow:none!important;background-image:url(../../images/Vectorcateory_Selected.svg)!important;background-repeat:no-repeat!important;background-position:right .75rem center!important;background-size:16px 12px!important;margin-bottom:60px}
.form-select option[value]{background:#000!important;color:#bdbdbd!important}
.form-select option[value]:hover{background:#f90!important;color:#f90!important}
.form-select option{background:#000!important;border:none!important;box-shadow:none!important}
.category_ranges{width:100%}
.category_ranges .ranges_price{width:100%}
.multi-range-slider .bar-inner{background-color:#f90!important;box-shadow:none!important}
.multi-range-slider .thumb *{background-color:#f90!important}
.cat_counter{display:flex;flex-direction:column;margin-bottom:25px}
.cat_counter div{display:flex;justify-content:space-between;align-items:center;padding:8px 10px;border:1px solid #828282;border-radius:4px;margin-bottom:8px}
.cat_counter div span{font-weight:500;font-size:14px;line-height:17px;color:#828282}
.cat_counter div p{font-weight:500;font-size:14px;line-height:17px;color:#828282;margin-bottom:0}
.pagination{width:100%;display:flex;justify-content:center;align-items:center;margin:90px 0}
.pagination .pagination-container .active{background:#f90;color:#fff;padding:10px 18px;border-radius:4px}
.pagination .pagination-container a{background:#fff;color:#828282;padding:10px 18px;border-radius:4px;text-decoration:none;margin:0 2px}
.pagination-container a.disabled{opacity:.2}
.pagination-container a:hover:not(.active){background-color:#eee}
.empty_product{width:100%;text-align:center;display:flex;flex-direction:column;justify-content:center;align-items:center}
.empty_product h2{color:#f90;margin-top:20px}
.rights .category_error{margin:20px auto;color:#f90;text-align:center;font-size:24px}
@media (max-width:1399px){
.categor_pc .rights .cards{width:220px}
}
@media (max-width:991px){
.categor_pc .rights .cards{width:47%;border-radius:4px}
.form-select option{font-size:14px!important}
.cat_counter div{padding:8px 7px}
.cat_counter div p{font-size:12px}
}
@media (max-width:767px){
.categor_pc .left{display:flex;flex-direction:column-reverse}
.categor_pc .left .counter_bottom{display:flex;flex-direction:column;margin-bottom:40px}
.categor_pc{padding-top:30px}
.cat_counter{margin-bottom:15px}
.multi-range-slider{width:100%}
.multi-range-slider .thumb *{display:flex;justify-content:center;align-items:center}
}
@media (max-width:575px){
.empty_product h2{color:#f90;font-size:22px}
.categor_pc .rights .cards .img_category img{width:100%;max-height:171px}
.categor_pc .rights .cards .img_category{margin-bottom:15px}
.categor_pc .rights .cards{padding:5px 5px 20px;width:47.6%;margin:4px 4px;border-radius:12px}
.categor_pc .left .top_categories{display:flex;flex-direction:column}
.categor_pc .rights .cards div span{font-size:17px;margin-bottom:5px}
.categor_pc .rights .cards div p{font-size:16px}
.categor_pc .rights .cards button{margin-top:15px}
.categor_pc .rights{justify-content:center}
.form-select{font-size:14px!important}
.form-select option{font-size:12px!important}
.multi-range-slider .thumb *{display:flex;justify-content:center;align-items:center}
.pagination .pagination-container a{background:#fff;color:#828282;padding:10px 16px;border-radius:6px;text-decoration:none;margin:0 2px}
.pagination .pagination-container .active{background:#f90;color:#fff;padding:10px 16px;border-radius:6px}
.rights .category_error{font-size:20px}
}