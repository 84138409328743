.btn{
  border: none;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #FF9900;
  border-radius: 8px;
  padding: 7px 12px;
  font-size: 16px;
}