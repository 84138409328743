.make .left{display:flex;flex-direction:column}
.make{padding:50px 0 10px}
.info_foto{background:#1c232e;padding:2.5rem;margin-bottom:60px}
.info_foto h4{color:#fff;margin-bottom:26px}
.info_foto .row .col-lg-3{padding:0 25px}
.info_foto img{width:20px;height:20px}
.right_info{display:flex;justify-content:space-between;align-items:center;position:relative}
.right_info img{width:200px;height:200px;position:absolute;right:0}
.info_foto p{color:#fff;line-height:28px;font-weight:300;margin-top:20px}
.make_img{width:30px;height:30px;display:flex;justify-content:center;align-items:center}
.make_left{display:flex;justify-content:flex-start;align-items:center;border-bottom:.5px solid #494949;padding-bottom:20px;margin-bottom:40px}
.make_left div p{font-weight:300;font-size:16px;line-height:15px;color:#bdbdbd;margin-bottom:5px}
.make_left div span{font-weight:500;font-size:15px;line-height:18px;color:#e0e0e0;margin-bottom:10px}
.make_img{margin-right:25px;cursor:pointer}
.path_links{display:flex;flex-direction:column;text-decoration:none}
.make_left div img{cursor:pointer;max-width:50px;max-height:50px;border-radius:3px}
.make .protsessor{display:flex;flex-direction:column;margin-bottom:80px}
.make .protsessor div span{font-weight:600;font-size:24px;line-height:29px;color:#f90}
.make .protsessor div select{width:250px;padding:6px 8px;background:0 0;color:#fff;border:.5px solid #474747;border-radius:4px;font-size:14px;font-weight:300;margin-left:5px}
.make .protsessor div select option{background:#000;color:#fff;padding:15px 0;font-weight:300;font-size:14px;border-radius:4px}
.make .col-lg-5.center{padding-left:30px}
.pc_part_cheked{margin-bottom:80px;display:flex;justify-content:space-between}
.pc_part_span span{font-weight:600;font-size:24px;line-height:29px;color:#f90}
.pc_part_cheked div img{background:#fff;border-radius:8px;width:45px;height:45px}
.pc_part_cheked img{width:50px;height:50px}
.pc_part_cheked .checked_bottom{display:flex;flex-direction:column;margin-left:10px}
.btn_oformit button{width:100%;justify-content:center;margin-bottom:16px}
.basket_top{background:#fff;padding:30px 20px;border-radius:8px;margin-bottom:30px}
.basket_top div{display:flex;justify-content:space-between;align-items:center;margin-bottom:10px}
.basket_top h6{font-weight:600;font-size:20px;color:#333;margin-bottom:24px}
.basket_top div span{font-size:14px;color:#333}
.basket_top div p{font-size:14px;line-height:17px;color:#333;margin:0}
.basket_top{width:100%}
.btn_oformit{width:100%}
.result_info{display:flex;justify-content:space-between}
.info_arrays{width:100%;display:flex;align-items:center;margin-top:20px}
.info_arrays div p{color:#fff;margin-bottom:0;font-size:18px;font-weight:400}
.info_arrays div img{width:50px;height:50px}
.info_price{margin-left:24px}
.info_price img{width:20px!important;height:20px!important;cursor:pointer;margin-left:8px}
.info_price span{color:#fff!important;font-weight:300!important;font-size:14px!important}
@media (max-width:1199px){
.make .protsessor div select{width:230px}
.make .protsessor div span{font-size:20px}
.basket_top{padding:20px 8px}
.basket_top div p{font-size:13px;font-weight:700}
.make .protsessor div span{font-size:22px}
}
@media (max-width:991px){
.basket_top{width:60%}
.btn_oformit{width:60%}
}
@media (max-width:575px){
.basket_top{width:100%}
.info_price{margin-left:24px}
.info_price{margin-left:10px}
.btn_oformit{width:100%}
.make .protsessor div span{font-size:18px}
.make .protsessor div select{width:60%}
.make .make_left{padding:0 15px;margin-bottom:20px;padding-bottom:20px}
.make .make_left div span{font-size:12px}
.pc_part_cheked .checked_bottom{margin-left:30px}
.pc_part_cheked .checked_bottom{margin-left:21px}
.info_foto{padding:1.5rem}
}