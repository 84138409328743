.images{height:600px;display:flex;flex-direction:column;justify-content:flex-end;padding-bottom:40px;padding-left:40px;background-repeat:no-repeat;background-size:contain;border-radius:6px;margin-top:5px}
.images span{font-weight:700;color:#fff;font-size:45px;border-radius:6px}
@media (max-width:1199px){
.images{max-height:450px}
.images .get_span span{margin-left:80px}
.images .get_span{margin-left:35px}
}
@media (max-width:991px){
.images .get_span span{font-size:30px}
.images{max-height:320px}
.images .get_span span{margin-left:50px}
}
@media (max-width:767px){
.images{height:245px;padding:0}
.images span{font-size:30px}
.images div{padding:20px}
.images .get_span span{margin-left:38px;font-size:24px}
.images .get_span{margin-bottom:15px}
}
@media (max-width:575px){
.image_slider{padding:0 5px!important}
.images div{display:none}
.images{min-height:170px;padding:0}
}