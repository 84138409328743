.payment{display:flex;flex-direction:column}
.bask_check{display:flex;flex-direction:column}
.bask_check .adres h4{margin-bottom:20px;margin-top:30px}
.bask_check .adres span{font-weight:500;font-size:18px;color:#828282;margin-bottom:8px}
.bask_check .adres{display:flex;flex-direction:column}
.bask_check .adres div{display:flex;width:100%}
.bask_check .adres div input{width:50%;padding:5px 12px;border:1px solid #6d6d6d;margin:5px;border-radius:3px}
.bask_check .adres div select{width:100%;padding:8px;background:#fff;border:1px solid #e0e0e0;border-radius:4px;color:#828282;margin-bottom:25px}
.bask_check .form-check{border:1px solid #828282;border-radius:4px;padding:5px;margin-bottom:10px}
.bask_check h4{font-weight:600;font-size:40px;color:#f90;margin-bottom:40px}
.bask_check .form-check .form-check-input{margin-left:0;margin-right:20px;margin-left:5px}
.bask_check .form-check .form-check-label{font-weight:500;font-size:16px;color:#828282}
.bask_check .form-check .form-check-input:checked{background-color:#f90;border-color:#f90}
@media (max-width:767px){
.payment_card h3{text-align:center}
}
@media (max-width:575px){
.bask_check h4{margin-top:20px;font-size:28px;margin-bottom:40px}
.bask_check .adres h4{margin-bottom:20px;margin-top:40px;font-size:26px}
.bask_check .payment_card{justify-content:center}
}