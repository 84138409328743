.myModal{position:fixed;top:0;left:0;bottom:0;right:0;font-family:system-ui;display:none;background:rgb(0,0,0,.6);z-index:2}
.modalContent{border-radius:16px;display:flex;justify-content:center;align-items:center;box-shadow:0 0 50px 10px #888}
.myModal.active{display:flex;justify-content:center;align-items:center}
.modalContent img{max-width:100%;max-height:700px;border-radius:6px}
@media (max-width:1199px){
.modalContent{border-radius:16px;display:flex;justify-content:center;align-items:center;box-shadow:0 0 50px 10px #888}
}
@media (max-width:991px){
.modalContent{padding:30 30px;border-radius:16px;max-width:60%}
}
@media (max-width:767px){
.modalContent{border-radius:16px}
}
@media (max-width:575px){
.modalContent{border-radius:16px;max-width:90%}
}