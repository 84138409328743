.footer .left{display:flex;flex-direction:column}
.footer{padding:40px 0 100px;background:#333}
.footer .left .logs{display:flex;justify-content:flex-start;align-items:center;margin-bottom:20px}
.footer .left .logs_href{display:flex;justify-content:flex-start;align-items:center;margin-bottom:20px}
.footer .left .log1{margin-bottom:40px}
.footer .left .logs a{text-decoration:none;font-style:normal;font-weight:400;font-size:18px;line-height:22px;margin-left:13px;color:#fff}
.footer .left .logs_href a{text-decoration:none;font-style:normal;font-weight:400;font-size:18px;line-height:22px;color:#fff}
.footer .left .log_span{margin-bottom:25px}
.footer .left .log_span div span{font-style:normal;font-weight:600;font-size:22px;line-height:22px;color:#f90}
.footer .bottom{display:flex;flex-direction:column;padding-left:20px}
.footer .bottom span{font-style:normal;font-weight:600;font-size:16px;line-height:28px;color:#f90;margin-bottom:40px}
.footer .bottom a{font-style:normal;font-weight:400;font-size:17px;line-height:28px;color:#fff;text-decoration:none;margin-bottom:24px}
@media (max-width:991px){
.footer .bottom{display:flex;flex-direction:column;padding-left:5px}
.footer .bottom span{line-height:25px}
}
@media (max-width:767px){
.footer{padding:60px 10px 60px}
.footer .left{margin-bottom:30px}
.footer .bottom{margin-top:20px}
.footer .bottom span{font-size:18px}
}
@media (max-width:575px){
.footer .bottom span{font-size:16px}
.footer .bottom a{margin-bottom:10px}
.footer .bottom a{font-size:16px}
}