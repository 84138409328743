.cards{border-radius:8px;display:flex;flex-direction:column;align-items:center;cursor:pointer;display:inline}
.card_bg{padding:0 5px}
.new_card{background:#fff;width:100%;display:flex;flex-direction:column;border-radius:16px;padding:10px;height:360px;justify-content:space-between}
.card_img{display:flex;justify-content:center;align-items:center}
.card_img img{max-width:100%;max-height:140px}
.card_title{overflow:hidden;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;margin:20px auto 10px;color:#666;font-size:18px;line-height:28px}
.card_price{display:flex;justify-content:space-between;align-items:center}
.card_price span{color:#2b2b2b;font-size:20px;font-weight:600}
.card_button button{display:flex;justify-content:center;width:100%;margin:15px 0 8px}
.title{margin-bottom:20px;display:flex;justify-content:space-between;align-items:center}
.title span{font-weight:600;font-size:48px;color:#f90}
.title p{font-style:normal;font-weight:500;font-size:18px;color:#f90;margin:0}
.title p img{margin-left:10px}
.cards .item{min-height:360px;background:#fff;padding:15px 10px 20px;margin:0 5px;border-radius:8px;display:flex;flex-direction:column;justify-content:space-between}
.cards div p{margin:0;font-weight:600;font-size:20px;color:#2b2b2b}
.cards div span{font-size:18px;color:#666;line-height:18px}
.cards button{width:100%;margin-bottom:10px}
@media (max-width:1199px){
.card_title{margin:6px auto}
.card_title{font-size:17px;line-height:24px}
.card_bg{height:100%}
}
@media (max-width:991px){
.title span{font-size:30px}
.card_bg{height:100%}
.new_card{height:330px}
.card_price span{font-size:18px}
}
@media (max-width:767px){
.title span{font-size:25px}
.new_card{height:330px}
}
@media (max-width:575px){
.card_bg{padding:0 3px}
.new_card{padding:10px 4px 0;justify-content:space-between;height:300px;border-radius:12px}
.card_title{margin:10px auto;font-size:15px;line-height:22px}
.card_price span{font-size:15px;font-weight:700}
.title p{font-style:normal;font-weight:500;font-size:13px;color:#f90;margin:0}
.title span{font-size:18px}
.card_button button{margin:14px 0 12px}
.card_img img{max-height:120px}
}