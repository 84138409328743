*{font-size:16px;font-weight:500;font-family:Montserrat}
html{scroll-behavior:smooth}
a{text-decoration:none}
.slick-arrow.slick-next{z-index:2;right:-5px}
.slick-arrow.slick-prev{z-index:2;left:-14px}
.slick-arrow.slick-next::before{padding:4px;background:#f90;border-radius:4px}
.slick-arrow.slick-prev::before{padding:4px;background:#f90;border-radius:4px}
.control-arrow.control-next{display:none}
.control-arrow.control-prev{display:none}
.slick-list{height:360px!important}
.image_slider .slick-list{height:auto!important}
@media (max-width:991px){
.slick-list{height:330px!important}
}
@media (max-width:767px){
.slick-list{height:330px!important}
}
@media (max-width:575px){
.slick-list{height:300px!important}
.image_slider .slick-list{height:180px!important}
}