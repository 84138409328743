.search_top{display:flex;justify-content:space-between;align-items:center;padding:20px 0}
.product_count{background:#ee9715;width:20px;height:20px;display:flex;justify-content:center;align-items:center;border-radius:50%;color:#fff;position:absolute;top:0;right:0}
.basket_icon{position:relative;display:flex;flex-direction:column;align-items:center;margin-left:"20px";cursor:pointer;margin-left:14px}
.basket_icon a{text-decoration:none;display:flex;flex-direction:column;justify-content:center;align-items:center}
.search_left{display:flex;align-items:center}
.search_left button{color:#fff;display:flex;justify-content:center;align-items:center;margin-left:12px;padding:15px}
.search_right{display:flex;align-items:center;justify-content:flex-end;width:100%}
.search_right div span{color:#777;font-size:11px}
.value_searcher{position:absolute;width:100%;background:#fff;top:45px;z-index:1;border-radius:8px;padding:5px;display:flex;flex-direction:column;border:1px solid #ee9715;max-height:400px;overflow:scroll;overflow-y:auto}
.value_searcher::-webkit-scrollbar{display:none}
.value_searcher div{width:100%;color:#000;padding:4px;padding-left:10px;border-radius:4px;margin-bottom:5px;cursor:pointer}
.value_searcher div:hover{background:#ee9715;color:#fff}
.search_right .search_right_search input{border:1px solid #ffb74a;border-radius:8px;color:#000;font-size:16px;padding:8px;width:100%}
.search_right_search{width:80%;position:relative;display:flex;justify-content:center;align-items:center}
.search_right div button{margin-left:20px}
.search_right_search form{width:100%}
.search_right_search label{position:absolute;right:.4%}
.search_right_search img{padding:8px;border-radius:5px 7px;background:#ee9715}
.mobile_basket_block{display:none}
.search_right .language span{font-weight:800px;font-size:16px;cursor:pointer;margin-left:8px;background:#ee9715;color:#fff;padding:10px;border-radius:4px}
@media (max-width:1199px){
.search_right div button{margin-left:10px;font-size:15px}
.basket_icon{margin-left:12px}
.search_right_search label{position:absolute;right:.6%}
}
@media (max-width:991px){
.search_left button{padding:15px}
.search_right .search_right_search input{min-width:380px}
.search_right div button{margin-left:9px;font-size:12px;padding:9px 3px}
.basket_icon{margin-left:9px}
.search_top{padding:15px 0}
.search_right_search img{padding:7px}
}
.search_left .basket_icon{display:none}
.basket_two{display:none}
@media (max-width:767px){
.search_right .search_right_search input{min-width:220px;padding:7px}
.search_right div button{margin-left:5px}
.search_left button{padding:12px 10px;margin-left:5px}
.basket_icon{margin-left:5px}
.search_right_search{width:72%}
.search_right_search label{right:1%}
}
@media (max-width:575px){
.search_top{display:flex;flex-direction:column;align-items:flex-end;padding:20px 5px}
.search_right div button{margin-left:10px}
.basket_icon{margin-left:10px}
.search_left{margin-bottom:15px}
.search_left .basket_icon{display:block}
.takes{display:none}
.search_right_search{width:100%}
.search_right_search input{width:100%}
.search_left{width:100%;display:flex;justify-content:space-between}
.mobile_basket_block{display:block;display:flex;justify-content:flex-end;align-items:center}
.basket_two{display:block;position:relative;margin-right:12px}
.basket_two img{width:25px}
.basket_two span{position:absolute;top:-10px;right:-10px;color:#fff;background:#f90;border-radius:50%;font-size:8px;width:20px;height:20px;display:flex;justify-content:center;align-items:center}
.search_left button{font-size:14px}
.basket_two a{text-decoration:none}
}