.product_left{display:flex}
.product h1{font-weight:600;font-size:35px;color:#f90;margin-bottom:30px;margin-top:80px}
.product .product_left{border-top:2px solid #828282;padding-top:30px}
.product_left .product_info h4{font-weight:600;font-size:32px;line-height:44px;color:#fff;margin-bottom:30px}
.product_left .product_info{margin-left:18px}
.product_left .product_info span{font-weight:600;font-size:24px;line-height:29px;color:#fff}
.product_left .product_info p{font-family:Montserrat;font-size:18px;line-height:25px;color:#a09e9e;margin-bottom:70px;margin-top:15px}
h6{font-size:18px;line-height:28px;color:#e0e0e0}
.product_left .foto_product{display:flex;flex-direction:column;justify-content:flex-start;align-items:center}
.product_left .foto_product .info_pc{display:flex;justify-content:center;align-items:center;background:#fff;cursor:pointer;padding:4px;border-radius:8px;margin-bottom:20px;box-sizing:border-box}
.product_left .foto_product .micro_foto{width:100%;display:flex;flex-wrap:wrap;justify-content:space-evenly}
.product_left .foto_product .micro_foto .micro_foto_dialog{display:flex;justify-content:center;align-items:center;width:100px;background:#fff;border-radius:8px;margin:4px;padding:2px;cursor:pointer;box-sizing:border-box}
.product_left .foto_product .micro_foto .micro_foto_dialog img{width:100%;border-radius:6px}
.product .right{height:100%}
.product .right button{width:100%;justify-content:center;padding:10px 12px}
.product .right .right_basket_bg{background:#fff;border-radius:8px;width:100%;padding:30px 20px;display:flex;flex-direction:column;margin-bottom:20px}
.product .right .right_basket_bg div{display:flex;justify-content:space-between;align-items:center;margin-bottom:6px}
.basproductket .right .right_basket_bg .count{font-weight:600;font-size:20px;margin-bottom:24px;color:#333}
.product .right .right_basket_bg div span{font-size:14px;line-height:17px;margin-bottom:0;color:#333}
.product .right .right_basket_bg div p{margin:0;font-size:14px;color:#333}
.product .right .count{font-weight:600;font-size:26px;color:#333}
.title_bottom h6{padding-top:100px;font-weight:600;font-size:24px;line-height:29px;color:#fff;border-bottom:1px solid #828282;padding-bottom:14px}
.title_bottom span{font-weight:600;font-size:16px;line-height:22px;color:#e0e0e0;padding-top:16px}
.product .info_bottom{display:flex;flex-direction:column;margin-top:40px}
.product .info_bottom div{display:flex;justify-content:space-between;align-items:center;border-bottom:1px solid #4f4f4f;margin-bottom:18px;padding-bottom:6px}
.product .info_bottom div span{font-weight:400;font-size:17px;line-height:22px;color:#bdbdbd}
.product .info_bottom div p{font-size:16px;text-align:right;color:#fff;margin-bottom:0}
.product .right_block{display:none}
.right_basket_bg{display:none}
.product_left .foto_product .info_pc img{max-width:280px;border-radius:4px}
.product .right .right_basket_bg div p{font-size:18px;font-weight:400;font-family:Arial,Helvetica,sans-serif;color:#000;margin-bottom:0}
@media (max-width:1199px){
.product .right .right_basket_bg{background:#fff;border-radius:8px;width:100%;padding:30px 15px}
}
@media (max-width:991px){
.product .right{display:none}
.right_basket_bg{display:block;display:flex;flex-direction:column;justify-content:center;background:#fff;border-radius:8px;padding:30px 24px;margin-top:30px}
.product_left .product_info p{margin-bottom:14px}
.product_left .product_info .right_basket_bg .count{font-weight:600;font-size:20px;color:#333;text-align:center;margin-bottom:24px}
.product_left .product_info .right_basket_bg button{margin-bottom:8px;justify-content:center}
}
.product_left .product_info .right_basket_bg .count{display:flex;justify-content:space-between}
.product h1{font-size:30px}
@media (max-width:767px){
.product_left .foto_product .info_pc img{max-width:320px;min-width:280px}
.product_left .foto_product .micro_foto{justify-content:space-evenly}
.product_left .product_info span{font-size:22px}
.product_left .product_info p{font-size:15px;line-height:24px}
.product_left .product_info{margin-left:22px}
.right_basket_bg{margin-top:46px}
.product_left{display:flex;flex-direction:column}
.product_left .foto_product{display:flex;justify-content:center;align-items:center}
.product_left .product_info h4{margin-top:35px}
.product_left .product_info p{font-size:18px}
.right_basket_bg{padding:20px 25px;width:100%}
.product_left .product_info .right_basket_bg button{margin-bottom:15px;justify-content:center}
.product h1{text-align:center}
.product_left .product_info{margin-left:0;padding:0 5px}
.product .info_bottom div span{font-size:14px}
.product .info_bottom div p{font-size:14px}
.product .info_bottom{padding:0 20px}
.product_left .foto_product .info_pc{width:auto;display:flex;justify-content:center;align-items:center}
.product_left .product_info .right_basket_bg .count{display:flex;justify-content:space-between}
}
@media (max-width:575px){
.product h1{font-size:30px;line-height:40px;margin:20px 0}
.product_left .product_info .right_basket_bg .count{display:flex;justify-content:space-between}
}