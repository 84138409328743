.right{display:flex;flex-direction:column;justify-content:center;align-items:flex-start}
.right h3{font-style:normal;font-weight:600;font-size:42px;line-height:59px;color:#f90;margin-bottom:10px}
.right span{font-style:normal;font-weight:400;font-size:18px;line-height:30px;margin-bottom:30px;color:#fff}
.my_pc_start .pc_start .left{display:flex;justify-content:center;align-items:center;margin-bottom:30px}
.my_pc_start{
  margin-bottom: 120px;
}
@media (max-width:1199px){
.my_pc_start .right{padding-left:50px}
.pc_start{margin-bottom:60px}
.my_pc_start .right h3{font-size:30px;line-height:38px}
}
@media (max-width:991px){
.my_pc_start .pc_start{padding:25px 0}
.my_pc_start .left div img{width:100%}
.my_pc_start .right{padding-left:20px}
.pc_start{margin-bottom:10px}
.my_pc_start .right h3{font-size:30px;line-height:30px}
.my_pc_start .right span{font-size:15px;line-height:26px;margin-bottom:20px}
}
@media (max-width:767px){
.my_pc_start .right h3{font-size:28px;line-height:30px}
.my_pc_start .right span{font-size:12px;line-height:26px}
.my_pc_start .right button{font-size:12px}
}
@media (max-width:575px){
.my_pc_start .left div img{width:80%}
.my_pc_start .left div{display:flex;justify-content:center;align-items:center}
.my_pc_start .right h3{font-size:20px}
.my_pc_start .right span{font-size:15px;line-height:27px}
.my_pc_start .right{padding:auto}
.my_pc_start .pc_start {
  padding: 0;
}
.my_pc_start{
  margin-bottom: 40px;
}
}